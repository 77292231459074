import React from 'react';

import { Link } from 'components/link/Link';
import ArrowSmall from 'assets/svg/arrow-small.svg';
import { useContext } from 'react';
import s from './Button.scss';
import { UIContext } from 'context/ui';

interface IProps {
  type?: "primary" | "secondary" | "secondary2arrow" | "tag" | null;
  label: string;
  href?: string;
  onClick?(): void;
  invertedColor?: boolean;
  hasBackArrow?: boolean;
  pageThemeLight?: boolean;
}

export const Button = ({ type = 'primary', label, href, onClick, invertedColor, hasBackArrow, pageThemeLight = false, colorOverride }: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);
  const isLink = typeof href !== 'undefined';
  const isExternal =
    isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');
  const isId = href && href[0] == '#' && href.length > 1;
  const buttonClass = type == 'primary'
                        ? s('button__primary', {invertedColor, hasBackArrow})
                        :  type == 'tag'
                          ? s('button__tag', {pageThemeLight})
                          : s('button__secondary', {invertedColor})
  
  const content = () => {
    if( type == 'primary' ) {
      return (
        <>
          { hasBackArrow && <ArrowSmall />}
          <span className={s.button__primary__label__wrapper}>
            <span className={s.button__primary__label} data-text={label}
              style={{color: colorOverride}}
            >
              {label}
            </span>
          </span>
        </>
      )
    } else if ( type == 'secondary2arrow' ) {
      return (
        <>
          {label}
          <div>
            <ArrowSmall />
            <ArrowSmall />
          </div>
        </>
      )
    } else if (type == 'tag') {
      return (
        <>
          <span className={s.button__tag__label__wrapper}>
            <span className={s.button__tag__label} data-text={label}>
              {label}
            </span>
          </span>
        </>
      )
    } else {
      return (
        <>
          {label} <ArrowSmall />
        </>
      )
    }
  }

  if (isId) {
    return (
      <div className={buttonClass} onClick={() => {
        const el = document.querySelector(href);
        const elemRect = el.getBoundingClientRect()
        const bodyRect = document.body.getBoundingClientRect()
        const offset = elemRect.top - bodyRect.top;
        if (window.smoothScroll && isDesktop) {
          window.smoothScroll.scrollTo(offset)
        }
        else {
          el.scrollIntoView()
        }
      }
      } 
      style={{
        borderColor: colorOverride || null
      }}>
        {content()}
      </div>
    );
  }

  if (isExternal) {
    return (
      <a
        className={buttonClass}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        style={{
          borderColor: colorOverride || null
        }}
      >
        {content()}
      </a>
    );
  }
  
  if (isLink) {
    return (
      <Link className={buttonClass} to={href || '#'} 
      style={{
        borderColor: colorOverride || null
      }}>
        {content()}
      </Link>
    );
  }


  if(!onClick) {
    return (
      <div className={buttonClass}>
        {content()}
      </div>
    )
  }

  return (
    <button className={buttonClass} onClick={onClick}
      style={{
        borderColor: colorOverride || null
      }}
    >
      {content()}
    </button>
  );
};
