import React, { ReactNode } from 'react';

import s from './Container.scss';

interface IProps {
  children: ReactNode;
}

export const Container = ({ children, style }: any) => (
  <section className={s.container} style={style}>{children}</section>
);
