import React from 'react';

import Helmet from 'react-helmet';


export const SeoComponent = ({
  seo,
  title,
  url='',
  description
}: any) => {

  // fallback values
  const safeTitle = (seo && seo.title) || title;
  const safeDescription = (seo && seo.description) || description;
  const safeType = 'website';
  const safeUrl = url;
  const safeImageOg = (seo && seo.facebookImage) || null;
  const safeImageTw = (seo && seo.twitterImage) || null;
  const twitterImageUrl = safeImageTw && safeImageTw.file.url.replace('//', 'https://')
  const noIndex = (seo && seo.no_index) || false;
  const meta = [];
  meta.push(
    // title
    { property: 'og:title', content: safeTitle },
    { name: 'twitter:title', content: safeTitle },
    // type
    { property: 'og:type', content: safeType },
    { name: 'twitter:card', content: 'summary_large_image' },
    // url
    { property: 'og:url', content: safeUrl },
    { name: 'twitter:url', content: safeUrl },
    // desc
    { name: 'description', content: safeDescription },
    { property: 'og:description', content: safeDescription },
    { name: 'twitter:description', content: safeDescription },
    // imageOg
    { property: 'og:image', content: safeImageOg && safeImageOg.file.url },
    { name:'image', content: safeImageOg && safeImageOg.url },
    { name: 'twitter:image', content: twitterImageUrl },
    // image Dimensions
    { property: 'og:image:width', content: safeImageOg && `${safeImageOg.file.details.image.width}px` },
    { property: 'og:image:height', content: safeImageOg && `${safeImageOg.file.details.image.height}px` },
    // image alt
    { property: 'og:image:alt', content: safeImageOg && safeImageOg.description },
    { name: 'twitter:image:alt', content: safeImageTw && safeImageTw.description },
    // author
    { name: 'author', content: 'Aurora Innovation Inc.' },
    // google-site-verification
    { name: 'google-site-verification', content: 'mgrXgIG3dFet19NZWsZGrGIXpDQls83q72-ovbf3qhA' }
  );

  noIndex &&
  meta.push(
    // noIndex
    { name: 'robots', content: 'noindex' },
  );

  return <Helmet title={safeTitle} meta={meta} />;
};

export const Seo = (props: any) => {
  
  return (
    <SeoComponent {...props}/>
  )
};