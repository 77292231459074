import React, { ReactNode } from 'react';

import s from './WrapperBgs.scss';

interface IProps {
  children: ReactNode;
  hasGradient?: boolean;
  overflow?: boolean;
  overflowMobile?: boolean;
  extraPaddingBottom?: boolean;
  extraClassName?: string;
  id?: string;
}

export const WrapperBgs = (
  { 
    children, 
    hasGradient = false, 
    overflow = false, 
    overflowMobile = false,
    extraPaddingBottom = false,
    extraClassName='',
    id='',
    style
  }: any) => (
  <div className={ s('wrapperBgs', {hasGradient}, {overflow}, {overflowMobile}, {extraPaddingBottom}, extraClassName) } id={id} style={{...style}}>
    {children}
  </div>
);
